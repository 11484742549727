@media screen and (max-width: 500px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }

  .carousel-root {
    height: auto;
  }
}


.item, .ss-item {
  height: 100%; /* Match the height of the carousel slider */
  width: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the entire slider area */
}