.qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    text-align: center;
  }
  
  .qr-code-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .qr-code-form input {
    padding: 10px;
    font-size: 16px;
    width: 200px;
  }
  
  .qr-code-form h2 {
    margin-bottom: 20px;
  }
  
  .qr-code {
    margin-top: 20px;
  }
  