/* src/components/Login.css */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f4f4f9;
    padding: 20px;
}

.login-container h2 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
}

.login-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.login-container form div {
    width: 100%;
    margin-bottom: 15px;
}

.login-container form label {
    display: block;
    font-size: 1em;
    margin-bottom: 5px;
    color: #666;
}

.login-container form input {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.login-container form input:focus {
    border-color: #007bff;
    outline: none;
}

.login-container form button {
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.login-container form button:hover {
    background-color: #0056b3;
}
