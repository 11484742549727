.webcam-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .input-container input {
    padding: 10px;
    font-size: 16px;
    width: 200px;
  }
  
  .webcam-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .webcam-wrapper button {
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .captured-image-container {
    margin-bottom: 20px;
  }
  
  .captured-image-container img {
    max-width: 100%;
    height: auto;
  }
  
  .qr-code {
    margin-top: 20px;
  }
  